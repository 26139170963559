import { tv, VariantProps } from "tailwind-variants";

import {
  alignVariants,
  colorVariants,
  responsiveVariants,
  weightVariants,
  whitespaceVariants,
} from "../constant";

export const textSubtitleVariants = tv(
  {
    base: "font-display font-bold tracking-tighter leading-none",
    variants: {
      size: {
        1: "text-[length:--text-new-xl] md:text-[length:--text-new-2xl]",
        2: "text-[length:--text-new-lg] md:text-[length:--text-new-xl]",
        3: "text-[length:--text-new-md] md:text-[length:--text-new-lg]",
        4: "text-[length:--text-new-base] md:text-[length:--text-new-md]",

      },
      color: {
        ...colorVariants,
      },
      align: {
        ...alignVariants,
      },
      whitespace: {
        ...whitespaceVariants,
      },
      weight: {
        ...weightVariants,
      },

    },
    defaultVariants: {
      size: 1,
      align: "default",
      whitespace: "normal",
    },
  },
  { responsiveVariants: [ ...responsiveVariants ],

  },
);

export type TextSubtitleVariantsProps = VariantProps<typeof textSubtitleVariants>;
const textSubtitleStyles = (variants: TextSubtitleVariantsProps) => textSubtitleVariants(variants);

export default textSubtitleStyles;
