import { tv, VariantProps } from "tailwind-variants";

import {
  alignVariants,
  colorVariants,
  responsiveVariants,
  weightVariants,
  whitespaceVariants,
} from "../constant";

export const textTitleVariants = tv(
  {
    base: "font-display uppercase font-bold tracking-tighter",
    variants: {
      size: {
        1: "text-[length:--text-new-4xl] md:text-[length:--text-new-5xl] leading-snug ",
        2: "text-[length:--text-new-3xl] md:text-[length:--text-new-4xl] leading-normal",
        3: "text-[length:--text-new-2xl] md:text-[length:--text-new-3xl] leading-relaxed",

      },
      color: {
        ...colorVariants,
      },
      align: {
        ...alignVariants,
      },
      whitespace: {
        ...whitespaceVariants,
      },
      weight: {
        ...weightVariants,
      },

    },
    defaultVariants: {
      size: 1,
      align: "default",
      whitespace: "normal",
    },
  },
  { responsiveVariants: [ ...responsiveVariants ],

  },
);

export type TextTitleVariantsProps = VariantProps<typeof textTitleVariants>;
const textTitleStyles = (variants: TextTitleVariantsProps) => textTitleVariants(variants);

export default textTitleStyles;
